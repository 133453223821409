import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row, Table} from "react-bootstrap";
import {getExchanges} from "../http/excangeAPI";
import moment from "moment-timezone";

const URLs = {
	'SBER_TRC20': 'https://www.bestchange.ru/tether-trc20-to-sberbank.html',
	'SBER_BEP20': 'https://www.bestchange.ru/tether-bep20-to-sberbank.html',
	'TINK_TRC20': 'https://www.bestchange.ru/tether-trc20-to-tinkoff.html',
	'TINK_BEP20': 'https://www.bestchange.ru/tether-bep20-to-tinkoff.html',
	'ALFA_TRC20': 'https://www.bestchange.ru/tether-trc20-to-alfaclick.html',
	'ALFA_BEP20': 'https://www.bestchange.ru/tether-bep20-to-alfaclick.html',
	'VTB_TRC20': 'https://www.bestchange.ru/tether-trc20-to-vtb.html',
	'VTB_BEP20': 'https://www.bestchange.ru/tether-bep20-to-vtb.html'
};

const calculatePercent = (a, b) => {
	return ((Math.abs(a - b)) / ((a + b) / 2) * 100).toFixed(2);
}

const Courses = () => {
	const [courses, setCourses] = useState({});
	const [status, setStatus] = useState(null);
	const [time, setTime] = useState(null);

	const getData = () => {
		setStatus('Получение курсов');
		getExchanges()
			.then((res) => {
				setStatus('Курсы получены');
				const _courses = {
					google: res.data.google,
					bestChanges: Object.fromEntries(
						Object.entries(res.data.bestChanges)
							.sort(([, a], [, b]) => b - a)
					)
				}

				const israelTime = moment().tz('Asia/Jerusalem');
				setTime(israelTime.format('HH:mm:ss'));

				setCourses({..._courses});
			})
			.catch(err => {
				setStatus(`Ошибка получения курсов: ${err.message}`);
			})
			.finally(() => {
				setStatus('Курсы обновлены');
			});
	}

	useEffect(() => {
		getData()
	}, []);

	const handleRowClick = (key) => {
		if (URLs[key]) {
			window.open(URLs[key], '_blank'); // Открывает ссылку в новом окне
		}
	};

	return (
		<Container fluid className="py-4">
			<Row className="justify-content-center">
				<Col xs={12} md={10} lg={3}>
					<div className="text-center mb-3">
						<Button onClick={getData} variant="primary">Обновить</Button>
					</div>

					<div className="table-responsive">
						<Table bordered className="text-center text-white w-100">
							<tbody>
							{status && (
								<tr>
									<td colSpan={2}>{status} {time && time}</td>
								</tr>
							)}
							{courses['google'] && (
								<tr>
									<td colSpan={2}>{courses['google']['USDToRUB']}</td>
								</tr>
							)}
							{courses['bestChanges'] &&
								Object.keys(courses['bestChanges']).map(el => (
									<tr key={el}
									    onClick={() => handleRowClick(el)}
									    style={{cursor: 'pointer'}}>
										<td className="text-nowrap">
											{el.replace('_', ' ')}
										</td>
										<td className="text-nowrap">
											{courses['bestChanges'][el]} ({courses['google']['USDToRUB'] < courses['bestChanges'][el] ? '+' : '-'}{calculatePercent(courses['google']['USDToRUB'], courses['bestChanges'][el])}%)
										</td>
									</tr>
								))
							}
							</tbody>
						</Table>
					</div>
				</Col>
			</Row>
		</Container>
	);
};

export default Courses;
